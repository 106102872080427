import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import "./Animal.css";

const RiveAnimal = () => {
  const STATE_MACHINE_NAME = "State Machine 1";

  const { rive, RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "rive/animal.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  return <RiveComponent className="character" />;
};

export default RiveAnimal;
