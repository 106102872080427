import React, { useEffect, useState } from "react";
import "./CoinCounter.css";

const CoinCounter = ({ coins }) => {
  const handleCalculateCoins = () => {
    return Math.ceil(coins / 3600);
  };

  const [animatedCoins, setAnimatedCoins] = useState(
    handleCalculateCoins(coins)
  );

  useEffect(() => {
    // Simple animation effect when coins increase
    const interval = setInterval(() => {
      setAnimatedCoins((prevCoins) => prevCoins + handleCalculateCoins(coins));
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <div className="coin-counter">
      <img className="coin-icon" src="images/coin.png" alt="Coin" />
      <div
        className={`coin-value ${coins > animatedCoins ? "coin-increase" : ""}`}
      >
        {animatedCoins}
      </div>
    </div>
  );
};

export default CoinCounter;
