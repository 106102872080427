import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import "./Book.css";

const RiveBook = () => {
  const STATE_MACHINE_NAME = "State Machine 1";
  const CLICK_NAME = "Click";

  const { rive, RiveComponent } = useRive({
    src: "rive/a_book.riv",
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  const OnClickInput = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    CLICK_NAME
  );

  const OnClick = () => {
    OnClickInput.fire();
  };

  return <RiveComponent className="book-container" onClick={() => OnClick()} />;
};

export default RiveBook;
